import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'
import { filter } from 'lodash'

import { parseACF } from '../utils'
import { Layout, PageHeader, Features, Grid, Block } from '../components'
import { media } from '../styles/utils'
import { container, padding, type, bgImage, button } from '../styles/global'

const ProductRange = props => {
    const graphQuery = useStaticQuery(query)
    const data = parseACF(graphQuery, 'allWordpressInfopages')
    console.log(data.products)

    const renderProducts = products => {
        const items = products.map((item, i) => {
            return (
                <Product key={i} as={Link} to={`/product/${item.slug}`}>
                    {item.acf.product_feature_image && (
                        <ImageWrapper>
                            <Image
                                key={item.acf.product_feature_image.sizes.medium2}
                                src={item.acf.product_feature_image.sizes.medium2}
                            >
                                {(src, loading) => {
                                    return (
                                        <BGImage 
                                            image={src} 
                                            style={{opacity: loading ? 0 : 1}}  
                                        />
                                    )
                                }}
                            </Image>
                        </ImageWrapper>
                    )}
                    <Info>
                        {item.title && (
                            <Heading>{item.title}</Heading>
                        )}
                        <Button>
                            See Product
                        </Button>
                    </Info>
                </Product>
            )
        })
        return (
            <Grid
                columns={2}
                items={items}
            />
        )
    }

    const renderProductSections = () => {
        if (!data.product_cats) return
        const items = data.product_cats.map((item, i) => {
            const products = filter(data.products, (o) => {
                return o.cats[0].slug === item.slug
            })

            return (
                <ProductCat key={i}>
                    <Heading>{item.title}</Heading>
                    {products && renderProducts(products)}
                </ProductCat>
            )
        })

        return (
            <Products>
                {items}
            </Products>
        )
    }

    const renderFeatures = () => {
        if (!data.features) return

        return (
            <Features
                bgColour={data?.features_background_colour}
                heading={data?.features_heading}
                features={data.features}
                link={data.features_include_link && data?.features_link}
            />
        )
    }


    return (
        <Layout
            meta={data && data.seo}
        >
            <Wrapper>
                <Container>
                    <PageHeader title={data?.intro_title || 'Product Range'} text={data?.intro_text} />
                    {renderProductSections()}
                </Container>
                {data.features && renderFeatures()}
                <Block
                    layout={'stockists_block'}
                />
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``

const ImageWrapper = styled.div``
const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
`
const Button = styled.div`
    ${button}
`


// Layout

const Wrapper = styled.div`
    ${tw`w-full`};
`

const Container = styled.div`
    ${container};
    ${padding};
`


// Products

const ProductCat = styled.div``
const Product = styled.div``
const Products = styled.div`
    padding-bottom: 12rem;

    ${ProductCat} {
        ${tw`text-center`};

        > ${Heading} {
            padding-bottom: 5rem; 
            ${tw`font-medium`};
            font-size: 2rem;
            line-height: 1.2;
        }

        &:not(:first-child) > ${Heading} {
            padding-top: 7rem;
        }
    }

    ${Product} {
        ${tw`flex flex-col`};
        box-shadow: 0 0 12px rgba(0,0,0,0.3);

        ${ImageWrapper} {
            position: relative;
            height: 0;
            padding-bottom: 81%;
            overflow: hidden;
        }

        ${BGImage} {
            ${tw`absolute inset-0 w-full h-full`};
            object-fit: cover;
        }

        ${Info} {
            ${tw`flex flex-col items-center pt-10 pb-12 px-4`};

            ${Heading} {
                ${tw`font-medium`};
                font-size: 2rem;
            }

            ${Button} {
                margin-top: 4rem;
                border-color: black;
                font-size: 1rem;
                color: black;

                &:hover {
                    ${tw`bg-black text-white`};
                }
            }
        }
    }
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "product-range" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default ProductRange
