import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import { getGlobalData } from '../utils'

import { parseACF } from '../utils'
import { Layout, PageHeader, ContactForm, CommercialForm } from '../components'
import { media } from '../styles/utils'
import { container, padding, type } from '../styles/global'

const mapEmbed = `<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12572.310666622032!2d145.1841583!3d-38.0219688!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7c52c595426e6515!2sSunstar%20Timber%20Flooring!5e0!3m2!1sen!2sau!4v1627532568319!5m2!1sen!2sau" style="border:0;" allowfullscreen="" loading="lazy"></iframe>`

const Contact = props => {
    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages')
    const { global: global_data } = getGlobalData()
    // console.log(data, global_data)

    return (
        <Layout
            meta={data && data.seo}
        >
            <Wrapper>
                <Container>
                    <PageHeader title={data?.intro_title || 'Contact Us'} text={data?.intro_text} />
                    <Grid>
                        <Sidebar className={'sidebar'}>
                            {global_data.footer_cta && (
                                <Heading
                                    dangerouslySetInnerHTML={{
                                        __html: global_data.footer_cta,
                                    }}
                                />
                            )}

                            {global_data.wholesale_message && (
                                <Subheading
                                    dangerouslySetInnerHTML={{
                                        __html: global_data.wholesale_message,
                                    }}
                                />
                            )}

                            {global_data.footer_contact_info && (
                                <Description
                                    className={'tiny-mce'}
                                    dangerouslySetInnerHTML={{
                                        __html: global_data.footer_contact_info,
                                    }}
                                />
                            )}
                        </Sidebar>
                        <Main className={'main'}>
                            <Heading>Or fill in the form</Heading>
                            <FormWrapper>
                                <ContactForm />
                            </FormWrapper>

                            <Heading style={{marginTop: '5rem'}}>Commercial Enquiries</Heading>
                            <FormWrapper>
                                <CommercialForm />
                            </FormWrapper>

                            <Heading style={{marginTop: '5rem'}}>Our location</Heading>
                            <MapWrapper>
                                <MapInnerWrapper
                                    dangerouslySetInnerHTML={{
                                        __html: mapEmbed,
                                    }}
                                />
                            </MapWrapper>
                        </Main>
                    </Grid>
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.div`
    ${tw`font-medium text-grey`};
    font-size: 2rem;
    line-height: 1.2;
`
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``


// Layout

const Wrapper = styled.div`
    ${tw`w-full`};
    padding-bottom: 9rem;
`

const Container = styled.div`
    ${container};
    ${padding};
`


const Sidebar = styled.div``
const Main = styled.div``

const Grid = styled.div`
    ${tw`flex -mx-8`};

    ${media.tabPor`
        ${tw`flex-col mx-0`};
    `}

    ${Sidebar}, ${Main} {
        ${tw`px-8`};
    }

    ${Sidebar} {
        ${tw`w-1/3`};

        ${Subheading} {
            ${type.body};
            margin-top: 3rem;
        }

        ${Description} {
            margin-top: 1.5rem;
        }

        ${media.tabPor`
            ${tw`w-full px-0`};
        `}
    }

    ${Main} {
        ${tw`w-2/3`};

        ${media.tabPor`
            ${tw`w-full px-0`};
            margin-top: 5rem;
        `}
    }
`

// Contact Form

const FormWrapper = styled.div`
    width: 100%;
    margin-top: 3rem;
    padding: 2rem 3rem 3rem;
    box-shadow: 0 0 12px rgba(0,0,0,0.3);

    ${media.mob`
        padding: 1rem 2rem 2rem;
    `}

    .form-heading {
        ${tw`font-body text-black`};
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.23;
    }

    .form-thanks {
        margin-top: 2rem;
        ${tw`font-body text-black`};
        font-weight: 300;
        font-size: 1rem;
        line-height: 1.23;
    }

    .dynamic-field {
        &, * {
            ${tw`font-body text-black`};
            font-weight: 300;
            font-size: 1rem;
            line-height: 1;
        }

        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }

        input, textarea {
            padding: 1rem 0;
        }
    }

    button {
        margin-top: 2.5rem;
        padding: 0.9rem 1.5rem 1rem !important;
        ${tw`font-body text-black border-black`};
        font-weight: 300;
        font-size: 1rem;
        line-height: 1;

        &:hover {
            ${tw`bg-black text-white`};
        }
    }
`

// Map

const MapWrapper = styled.div`
    width: 100%;
    margin-top: 3rem;
    padding: 3rem;
    box-shadow: 0 0 12px rgba(0,0,0,0.3);

    ${media.mob`
        padding: 2rem;
    `}
`

const MapInnerWrapper = styled.div`
    ${tw`relative w-full overflow-hidden`};
    height: 0;
    padding-bottom: 76.1643836%;

    iframe {
        ${tw`absolute inset-0 w-full h-full`};
    }
`


export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "contact" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Contact
