import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'

import { parseACF } from '../utils'
import { Layout, PageHeader } from '../components'
import { media } from '../styles/utils'
import { container, padding } from '../styles/global'

const About = props => {
    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages')
    console.log(data)

    return (
        <Layout
            meta={data && data.seo}
        >
            <Wrapper>
                <Container>
                    <PageHeader title={data?.intro_title || 'About'} text={data?.intro_text} />
                    <Grid>
                        <Left>
                            {data.about_text && (
                                <Text
                                    className={'tiny-mce'}
                                    dangerouslySetInnerHTML={{
                                        __html: `${data.about_text}`,
                                    }}
                                />
                            )}
                        </Left>
                        <Right>
                            {data.about_image && (
                                <Image
                                    key={data.about_image.id}
                                    src={data.about_image.sizes.medium2}
                                >
                                    {(src, loading) => {
                                        return (
                                            <LoadedImage
                                                src={src} 
                                                style={{opacity: loading ? 0 : 1}}  
                                            />
                                        )
                                    }}
                                </Image> 
                            )}
                        </Right>
                    </Grid>
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`

// Layout

const Wrapper = styled.div`
    ${tw`w-full`};
    padding-bottom: 9rem;
`

const Container = styled.div`
    ${container};
    ${padding};
`

const Left = styled.div``
const Right = styled.div``

const Grid = styled.div`
    ${tw`flex -mx-8`};

    ${media.mob`
        ${tw`flex-col mx-0`};
    `}

    ${Left}, ${Right} {
        ${tw`w-1/2 px-8`};

        ${media.mob`
            ${tw`w-full px-0`};
        `}
    }

    ${Left} {
        ${media.mob`
            order: 1;
            ${tw`mt-16`};
        `}
    }
`

export const Text = styled.div`
    /* check global .tiny-mce styles */
`



export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "about" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default About
