import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import { getGlobalData } from '../utils'

import { parseACF } from '../utils'
import { Layout, PageHeader, CommercialForm } from '../components'
import { media } from '../styles/utils'
import { container, padding, type } from '../styles/global'

const Commercial = props => {
    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages')
    // const { global: global_data } = getGlobalData()
    // console.log(data, global_data)

    return (
        <Layout
            meta={data && data.seo}
        >
            <Wrapper>
                <Container>
                    <PageHeader title={data?.intro_title || 'Commercial'} text={data?.intro_text} />
                    <Grid>
                        <Main className={'main'}>
                            <FormWrapper>
                                <CommercialForm />
                            </FormWrapper>
                        </Main>
                    </Grid>
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.div`
    ${tw`font-medium text-grey`};
    font-size: 2rem;
    line-height: 1.2;
`
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``


// Layout

const Wrapper = styled.div`
    ${tw`w-full`};
    padding-bottom: 9rem;
`

const Container = styled.div`
    ${container};
    ${padding};
`


const Main = styled.div``

const Grid = styled.div`
    ${tw`flex`};

    ${media.tabPor`
        ${tw`flex-col`};
    `}

    ${Main} {
        ${tw`w-2/3`};

        ${media.tabPor`
            ${tw`w-full`};
        `}
    }
`

// Commercial Form

const FormWrapper = styled.div`
    width: 100%;
    padding: 2rem 3rem 3rem;
    box-shadow: 0 0 12px rgba(0,0,0,0.3);

    ${media.mob`
        padding: 1rem 2rem 2rem;
    `}

    .form-heading {
        ${tw`font-body text-black`};
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.23;
    }

    .form-thanks {
        margin-top: 2rem;
        ${tw`font-body text-black`};
        font-weight: 300;
        font-size: 1rem;
        line-height: 1.23;
    }

    .dynamic-field {
        &, * {
            ${tw`font-body text-black`};
            font-weight: 300;
            font-size: 1rem;
            line-height: 1;
        }

        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }

        &#checkboxRetailer,
        &#checkboxDesignerSamples,
        &#checkboxDesignerProduct,
        &#checkboxInstaller {
            margin-bottom: 0;
        }

        input, textarea {
            padding: 1rem 0;
        }
    }

    button {
        margin-top: 2.5rem;
        padding: 0.9rem 1.5rem 1rem !important;
        ${tw`font-body text-black border-black`};
        font-weight: 300;
        font-size: 1rem;
        line-height: 1;

        &:hover {
            ${tw`bg-black text-white`};
        }
    }
`

// Map

const MapWrapper = styled.div`
    width: 100%;
    margin-top: 3rem;
    padding: 3rem;
    box-shadow: 0 0 12px rgba(0,0,0,0.3);

    ${media.mob`
        padding: 2rem;
    `}
`

const MapInnerWrapper = styled.div`
    ${tw`relative w-full overflow-hidden`};
    height: 0;
    padding-bottom: 76.1643836%;
`


export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "commercial-enquiries" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Commercial
