import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'
import { filter } from 'lodash'

import { parseACF } from '../utils'
import { Layout, PageHeader, Features, Block } from '../components'
import { media } from '../styles/utils'
import { container, padding, type, bgImage, button } from '../styles/global'

const ProductGuides = props => {
    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages')
    console.log(data)

    const renderDownloads = (product) => {
        const items = product.acf.product_downloads.map((item, i) => {
            return (
                <Product key={i}>
                    {item.image && (
                        <Image
                            key={item.image.sizes.medium2}
                            src={item.image.sizes.medium2}
                        >
                            {(src, loading) => {
                                return (
                                    <LoadedImage 
                                        src={src} 
                                        style={{opacity: loading ? 0 : 1}}  
                                    />
                                )
                            }}
                        </Image>
                    )}
                    <Info>
                        {product.title && (
                            <Heading>{product.title}</Heading>
                        )}
                        <Button
                            as={ExternalLink}
                            href={item?.file}
                            target="_blank"
                        >
                            {item.title}
                        </Button>
                    </Info>
                </Product>
            )
        })

        return items
    }

    const renderProducts = products => {

        const items = products.map((product, i) => {
            return (
                renderDownloads(product)
            )
        })

        return (
            <Grid>
                {items}
            </Grid>
        )
    }

    const renderProductSections = () => {
        if (!data.product_cats) return
        const items = data.product_cats.map((item, i) => {
            const products = filter(data.products, (o) => {
                return o.cats[0].slug === item.slug
            })

            return (
                <ProductCat key={i}>
                    <Heading>{item.title}</Heading>
                    {products && renderProducts(products)}
                </ProductCat>
            )
        })

        return (
            <Products>
                {items}
            </Products>
        )
    }

    const renderFeatures = () => {
        if (!data.features) return

        return (
            <Features
                bgColour={data?.features_background_colour}
                heading={data?.features_heading}
                features={data.features}
                link={data.features_include_link && data?.features_link}
            />
        )
    }

    return (
        <Layout
            meta={data && data.seo}
        >
            <Wrapper>
                <Container>
                    <PageHeader title={data?.intro_title || 'Product Guides and Warranties'} text={data?.intro_text} />
                    {renderProductSections()}
                </Container>
                {data.features && renderFeatures()}
                <Block
                    layout={'stockists_block'}
                />
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``
const ExternalLink = styled.a``

const ImageWrapper = styled.div``
const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`
const Button = styled.div`
    ${button}
`


// Layout

const Wrapper = styled.div`
    ${tw`w-full`};
`

const Container = styled.div`
    ${container};
    ${padding};
`


// Grid

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    width: 100%;

    ${media.tab`
        grid-template-columns: repeat(3, 1fr);
    `}

    ${media.mob`
        grid-template-columns: repeat(1, 1fr);
    `}
`


// Products

const ProductCat = styled.div``
const Product = styled.div``
const Products = styled.div`
    ${ProductCat} {
        ${tw`text-center`};

        > ${Heading} {
            padding-bottom: 5rem; 
            ${tw`font-medium`};
            font-size: 2rem;
            line-height: 1.2;
        }

        &:not(:first-child) > ${Heading} {
            padding-top: 7rem;
        }
    }

    ${Product} {
        ${tw`flex flex-col`};
        box-shadow: 0 0 12px rgba(0,0,0,0.3);

        ${LoadedImage} {}

        ${Info} {
            flex-grow: 1;
            ${tw`flex flex-col justify-between items-center pt-10 pb-12 px-4`};

            ${Heading} {
                ${tw`font-medium`};
                font-size: 1rem;
            }

            ${Button} {
                margin-top: 1.5rem;
                border-color: black;
                font-size: 1rem;
                color: black;

                &:hover {
                    ${tw`bg-black text-white`};
                }
            }
        }
    }
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "product-guides" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default ProductGuides
